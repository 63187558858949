import { isDefined } from "@clipboard-health/util-ts";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import type { ShiftRateNegotiationStatus } from "@src/appV2/OpenShifts/RateNegotiation/types";

import { getShiftWorkDurationInHours } from "../utils/getShiftWorkDuration";
import {
  type Offer,
  type OpenShift,
  type OpenShiftWorkplace,
  type RateNegotiation,
} from "./Open/types";
import { type ShiftSlotCoalesced } from "./Open/useGetShiftsSlotV2";
import { resolveShiftType } from "./resolveShiftType";
import {
  type ModalShiftData,
  type ModalShiftOfferData,
  type ModalShiftRateNegotiationData,
  type ModalWorkplaceData,
} from "./types";

function convertWorkplaceToModalWorkplaceData(workplace: OpenShiftWorkplace): ModalWorkplaceData {
  return {
    id: workplace.id,
    attributes: {
      name: workplace.attributes.name,
      type: workplace.attributes.type,
      location: workplace.attributes.location,
      rating: workplace.attributes.rating,
      requiresLunchBreak: workplace.attributes.requiresLunchBreak,
    },
  };
}

function convertRateNegotiationToModalShiftRateNegotiationData(
  rateNegotiation?: RateNegotiation
): ModalShiftRateNegotiationData | undefined {
  if (!isDefined(rateNegotiation)) {
    return undefined;
  }

  return {
    status: rateNegotiation.attributes.status as ShiftRateNegotiationStatus,
  };
}

/**
 * shiftWorkDurationInHours is the "work" duration of the shift.
 * This means that if the shift has a mandatory break,
 * the shiftWorkDurationInHours needs to be the scheduled duration of the shift minus the break (30 minutes).
 */
export function convertOfferToModalShiftOfferData(
  shiftWorkDurationInHours: number,
  offer?: Offer
): ModalShiftOfferData | undefined {
  if (!isDefined(offer)) {
    return undefined;
  }

  const { amountInMinorUnits } = offer.attributes.pay;

  const hourlyPay = amountInMinorUnits / 100;
  const totalPay = (shiftWorkDurationInHours * amountInMinorUnits) / 100;

  return {
    id: offer.id,
    attributes: {
      expiresAt: offer.attributes.expiresAt,
      hourlyPay,
      totalPay,
      formattedTotalPay: formatDollarsAsUsd(totalPay),
      formattedHourlyPay: formatDollarsAsUsd(hourlyPay),
    },
  };
}

interface ConvertOpenShiftToModalShiftDataProps {
  shift: OpenShift;
  workplace: OpenShiftWorkplace;
  offer?: Offer;
  rateNegotiation?: RateNegotiation;
  shiftSlots?: ShiftSlotCoalesced;
}

export function convertOpenShiftToModalShiftData(
  props: ConvertOpenShiftToModalShiftDataProps
): ModalShiftData {
  const { shift, workplace, offer, rateNegotiation } = props;
  const shiftWorkDurationInHours = getShiftWorkDurationInHours(
    { start: shift.attributes.start, end: shift.attributes.end },
    workplace.attributes.requiresLunchBreak ?? false
  );

  return {
    id: shift.id,
    workplace: convertWorkplaceToModalWorkplaceData(workplace),
    rateNegotiation: convertRateNegotiationToModalShiftRateNegotiationData(rateNegotiation),
    offer: convertOfferToModalShiftOfferData(shiftWorkDurationInHours, offer),
    attributes: {
      type: resolveShiftType({
        isUrgent: shift.attributes.isUrgent,
        window: shift.attributes.window,
        priorityTill: shift.attributes.priorityTill,
      }),
      timeSlot: shift.attributes.timeSlot,
      start: shift.attributes.start,
      end: shift.attributes.end,
      durationInHours: shiftWorkDurationInHours,
      qualification: shift.attributes.qualification,
      isUrgent: shift.attributes.isUrgent,
      urgency: shift.attributes.urgency,
      window: shift.attributes.window,
      priorityTill: shift.attributes.priorityTill,
      // TODO: We don't have that data in worker-bff but so far we only use it
      // inside the shift modal, so we can just rely on legacy bff endpoint data
      isInterested: false,
    },
  };
}
