import {
  DotSeparatedList,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { type GeoLocation } from "@src/appV2/Location";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { minutesToMilliseconds } from "date-fns";

import { ReviewsSummary } from "../Review/ReviewsSummary";
import { useDistanceToWorkplace } from "../Workplace/useDistanceToWorkplace";

interface ShiftWorkplaceListItemProps {
  workplaceName: string;
  workplaceType: string;
  workplaceId: string;
  workplaceLocation: GeoLocation;
  onClick: () => void;
}

export function ShiftWorkplaceListItem(props: ShiftWorkplaceListItemProps) {
  const { workplaceName, workplaceType, workplaceId, workplaceLocation, onClick } = props;

  const { formattedDistance } = useDistanceToWorkplace({
    workplaceGeoLocation: workplaceLocation,
  });

  const { data: ratingBreakdowns } = useGetRatingAggregates(workplaceId, {
    enabled: true,
    staleTime: minutesToMilliseconds(5),
  });

  return (
    <ListItemButton
      onClick={() => {
        onClick();
      }}
    >
      <ListItemIcon iconType="map-pin" />
      <ListItemText
        disableTypography
        primary={
          <Text semibold variant="h6" sx={{ textWrap: "balance" }}>
            {workplaceName}
          </Text>
        }
        secondary={
          <Stack spacing={4} sx={{ marginTop: 2 }}>
            <Text variant="body2">
              <DotSeparatedList inline>
                {workplaceType}
                {isDefined(formattedDistance) && `${formattedDistance} from home`}
              </DotSeparatedList>
            </Text>

            <ReviewsSummary rating={ratingBreakdowns?.avgRating ?? 0} size="small" />
          </Stack>
        }
      />
    </ListItemButton>
  );
}
