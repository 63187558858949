import { Pill } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack, type Theme, useTheme } from "@mui/material";
import { addHours, isBefore, isSameMinute, parseISO, startOfMinute } from "date-fns";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";
import { formatTime } from "../../utils/formatTime";
import { OverlappingShiftWorkersAvatars } from "../../WorkWithFriends/components/OverlappingShiftWorkersAvatars";
import { type WorkersForOverlappingShiftSlots } from "../../WorkWithFriends/hooks/useGetWorkersForOverlappingShiftSlots";
import { CurrentShiftStage } from "../constants";
import { type TimeSlot } from "../Open/types";
import { TimeSlotIcon } from "../TimeSlotIcon";
import { DotSeparatedText } from "./DotSeparatedText";
import { HighlightedWorkerShiftCardWrapper } from "./HighlightedCardWrapper";

export interface HighlightedWorkerShiftCardProps {
  href: string;
  workplaceName: string;
  workplaceTimezone: string;
  currentDate: Date;

  shiftStart: string;
  shiftEnd: string;
  shiftPosition: string;
  shiftHourlyPay?: string;
  shiftTotalPay?: string;
  shiftStage: CurrentShiftStage;
  shiftTimeSlot: TimeSlot;
  workersForOverlappingShiftSlot?: WorkersForOverlappingShiftSlots;
}
function getShiftStatusParameters(props: {
  workplaceName: string;
  workplaceTimezone: string;
  shiftStage: CurrentShiftStage;
  shiftPosition: string;
  startDate: Date;
  endDate: Date;
  currentDate: Date;
  theme: Theme;
}) {
  const {
    workplaceName,
    workplaceTimezone,
    shiftStage,
    shiftPosition,
    startDate,
    endDate,
    currentDate,
    theme,
  } = props;

  let titleText = "";
  let pillText = "";
  let pillColor = theme.palette.background.tertiary;
  let buttonText = "View details";

  const startsIn = formatDateRangeDurationCompact({
    startDate: startOfMinute(currentDate),
    endDate: startDate,
  });
  const formattedStartTime = formatTime(startDate, workplaceTimezone);
  const shiftDetails = `${formattedStartTime} ${shiftPosition} shift at ${workplaceName}`;

  switch (shiftStage) {
    case CurrentShiftStage.NOT_STARTED: {
      const now = startOfMinute(currentDate);
      const isShiftStarted = isBefore(startDate, now) || isSameMinute(startDate, now);
      const isWithinOneHour = isBefore(startDate, addHours(now, 1));

      if (isShiftStarted) {
        pillText = "Shift started";
        titleText = `Clock in to your ${shiftDetails}`;
        buttonText = "View details and clock in";
      } else {
        pillText = `Starts in ${startsIn}`;
        titleText = `Prepare for your ${shiftDetails}`;
        if (isWithinOneHour) {
          buttonText = "View details and clock in";
        }
      }

      break;
    }

    case CurrentShiftStage.CLOCKED_IN:
    case CurrentShiftStage.BREAK_STARTED:
    case CurrentShiftStage.BREAK_ENDED: {
      const now = startOfMinute(currentDate);
      const isShiftEnded = isBefore(endDate, now);

      if (isShiftEnded) {
        const timePastEnd = formatDateRangeDurationCompact({
          startDate: endDate,
          endDate: now,
        });
        pillText = "In progress";
        pillColor = theme.palette.intent?.success.background;
        titleText = `It is ${timePastEnd} past scheduled shift end. Please clock out soon.`;
        buttonText = "View details and clock out";
      } else {
        const endsIn = formatDateRangeDurationCompact({
          startDate: now,
          endDate,
        });
        pillText = "In progress";
        pillColor = theme.palette.intent?.success.background;
        titleText = `Your shift has ${endsIn} remaining`;
      }

      break;
    }

    case CurrentShiftStage.CLOCKED_OUT: {
      pillText = "Clocked out";
      titleText = "Submit your timesheet before you leave the workplace";
      buttonText = "Submit timesheet";

      break;
    }

    case CurrentShiftStage.PENDING_VERIFICATION: {
      pillText = "Clocked out";
      titleText = `Timesheet is pending verification for your ${shiftDetails}`;
      break;
    }

    case CurrentShiftStage.VERIFIED: {
      pillText = "Verified";
      titleText = `Timesheet has been verified for your ${shiftDetails}`;
      break;
    }

    default:
  }

  return { titleText, pillText, pillColor, buttonText };
}

export function HighlightedWorkerShiftCard(props: HighlightedWorkerShiftCardProps) {
  const {
    href,
    workplaceName,
    workplaceTimezone,
    shiftStart,
    shiftEnd,
    shiftPosition,
    shiftHourlyPay,
    shiftTotalPay,
    shiftStage,
    shiftTimeSlot,
    currentDate,
    workersForOverlappingShiftSlot,
  } = props;
  const theme = useTheme();

  const startDate = parseISO(shiftStart);
  const endDate = parseISO(shiftEnd);

  const history = useHistory();

  const { titleText, pillText, pillColor, buttonText } = getShiftStatusParameters({
    shiftStage,
    shiftPosition,
    startDate,
    endDate,
    currentDate,
    workplaceName,
    workplaceTimezone,
    theme,
  });

  return (
    <HighlightedWorkerShiftCardWrapper shiftTimeSlot={shiftTimeSlot}>
      <CardContent
        onClick={() => {
          history.push(href);
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Pill size="medium" label={pillText} backgroundColor={() => pillColor} />
          <Stack direction="row" alignItems="center" spacing={1}>
            {isDefined(workersForOverlappingShiftSlot) && (
              <OverlappingShiftWorkersAvatars
                overlappingShiftWorkers={workersForOverlappingShiftSlot}
                profileImageSizeInPx={32}
              />
            )}
            <TimeSlotIcon timeSlot={shiftTimeSlot} size="medium" />
          </Stack>
        </Stack>

        <Stack direction="column" spacing={4} marginTop={12}>
          <Text variant="h5">{titleText}</Text>
          <DotSeparatedText>
            {[
              isDefined(shiftHourlyPay) && isDefined(shiftTotalPay) && `${shiftHourlyPay} /hr`,
              isDefined(shiftHourlyPay) && isDefined(shiftTotalPay) && `${shiftTotalPay} total`,
            ].filter(Boolean)}
          </DotSeparatedText>
          <Button
            role="button"
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              history.push(href);
            }}
          >
            {buttonText}
          </Button>
        </Stack>
      </CardContent>
    </HighlightedWorkerShiftCardWrapper>
  );
}
