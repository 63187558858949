import { type ShiftModal } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import type {
  ShiftRateNegotiationStatus,
  WorkplaceShiftRateNegotiability,
} from "@src/appV2/OpenShifts/RateNegotiation/types";

import { getShiftWorkDurationInHours } from "../utils/getShiftWorkDuration";
import { convertOfferToModalShiftOfferData } from "./convertOpenShiftToModalShiftData";
import { type Offer } from "./Open/types";
import { resolveShiftType } from "./resolveShiftType";
import {
  type ModalShiftData,
  type ModalShiftRateNegotiationData,
  type ModalShiftSpecificationData,
  type ModalWorkplaceData,
} from "./types";

function convertWorkplaceToModalWorkplaceData(
  workplace: ShiftModal["attributes"]["workplace"]
): ModalWorkplaceData {
  return {
    id: workplace.id,
    attributes: {
      name: workplace.name,
      type: workplace.type,
      location: workplace.location,
      rating: workplace.rating,
      requiresLunchBreak: workplace.requiresLunchBreak ?? false,
      providesRestBreaks: workplace.providesRestBreaks ?? false,
      preventDoubleShifts: workplace.preventDoubleShifts ?? false,
      maxAllowedWorkHoursPerWeek: workplace.maxAllowedWorkHoursPerWeek ?? undefined,
      maxAllowedWorkConsecutiveHours: workplace.maxAllowedWorkConsecutiveHours ?? undefined,
      rateNegotiation: (workplace.rateNegotiationStatus ??
        "OFF") as WorkplaceShiftRateNegotiability,
    },
  };
}

function convertRateNegotiationToModalShiftRateNegotiationData(
  rateNegotiation?: ShiftModal["attributes"]["rateNegotiation"]
): ModalShiftRateNegotiationData | undefined {
  if (!isDefined(rateNegotiation)) {
    return undefined;
  }

  return {
    status: rateNegotiation.status as ShiftRateNegotiationStatus,
  };
}

interface ConvertOpenShiftToModalShiftDataProps {
  shiftModal: ShiftModal;
  offer?: Offer;
}

export function mapShiftModalData(props: ConvertOpenShiftToModalShiftDataProps): ModalShiftData {
  const { shiftModal, offer } = props;
  const shiftWorkDurationInHours = getShiftWorkDurationInHours(
    {
      start: shiftModal.attributes.shift.start,
      end: shiftModal.attributes.shift.end,
    },
    shiftModal.attributes.workplace.requiresLunchBreak ?? false
  );

  return {
    id: shiftModal.id,
    workplace: convertWorkplaceToModalWorkplaceData(shiftModal.attributes.workplace),
    rateNegotiation: convertRateNegotiationToModalShiftRateNegotiationData(
      shiftModal.attributes.rateNegotiation
    ),
    offer: convertOfferToModalShiftOfferData(shiftWorkDurationInHours, offer),
    attributes: {
      type: resolveShiftType({
        isUrgent: shiftModal.attributes.shift.isUrgent,
        window: shiftModal.attributes.shift.window,
        priorityTill: shiftModal.attributes.shift.priorityTill,
      }),
      timeSlot: shiftModal.attributes.shift.timeSlot,
      start: shiftModal.attributes.shift.start,
      end: shiftModal.attributes.shift.end,
      durationInHours: shiftWorkDurationInHours,
      qualification: shiftModal.attributes.shift.qualification,
      isUrgent: shiftModal.attributes.shift.isUrgent,
      urgency: shiftModal.attributes.shift.urgency,
      window: shiftModal.attributes.shift.window,
      priorityTill: shiftModal.attributes.shift.priorityTill,
      isInterested: shiftModal.attributes.shift.isInterested,
      specifications: shiftModal.attributes.shift.specifications as ModalShiftSpecificationData[],
    },
  };
}
